q::before {
  content: "“";
  font-family: 'Belwe';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #F1A638;
}

q::after {
  content: "”";
  font-family: 'Belwe';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 18px;
  color: #F1A638;
}


@media screen and (min-width: 768px) {
  q::before {
    font-size: 40px;
    line-height: 35px;
  }

  q::after {
    font-size: 40px;
    line-height: 35px;
  }
}
